.Section__container___3YYTG {
  font-weight: 400;
}

.Section__actionRow___2LWSU {
  margin-bottom: 15px;
}

.Section__sectionHeader___2djyg {
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.Section__sectionHeaderHint___3Wxdc {
  color: var(--grey);
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}

.Section__sectionBody___ihqqd {
  margin-bottom: 30px;
}

.Section__sectionFooter___1T54C {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___2r9ZX {
  margin-left: auto;
}

.Section__sectionFooterSecondaryContent___Nj41Q {
  margin-right: auto;
  align-self: center;
}

@media only screen and (max-width: 599px) {
  .Section__sectionFooter___1T54C {
    flex-wrap: wrap;
  }

  .Section__sectionFooterPrimaryContent___2r9ZX {
    width: 100%;
    margin-bottom: 32px;
  }

  .Section__sectionFooterPrimaryContent___2r9ZX > button {
    width: 100%;
  }

  .Section__sectionFooterSecondaryContent___Nj41Q {
    text-align: center;
    flex: 0 0 100%;
  }
}